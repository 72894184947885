<template>
<div class="sm-container">
  <div class="order">
    <h1>Confirm Order</h1>
    <div class="cart-table">
      <div class="cart-title">
        <b>Order List</b>
      </div>
      <div class="cart-item">
        <div class="cart-item-1 cart-item-1-txt">Component*Qty</div>
        <div class="cart-item-2 cart-item-1-txt">Product Number</div>
      </div>

      <div class="cart-item" v-for="item in cartList" :key="item.id">
        <div class="cart-item-1 product-info">
          <div class="product-info-img">
            <img :src="item.image">
          </div>
          <div class="product-info-txt">
            <p><b>{{ item.name }}</b></p>
            <div>
              <span>{{ item.part_no }}</span> <span>x{{ item.quantity }}</span>
            </div>
          </div>
        </div>
        <div class="cart-item-2 product-no">
          <span>No: {{ item.product }}</span>
        </div>
      </div>
    </div>
    <div class="thanks">
      <p>Thank you for your trust</p>
      <p> Please fill out the form below to confirm your order</p>
    </div>

    <div class="order-tips">
      <p>Replacement Parts take approximately 7-10  business days to receive.</p>
    </div>

    <div class="upload">
      <h3>Item/Defective Part Photo<span>Upload up to 3 images, with a maximum of 5M per image</span></h3>
      <Upload @photos-updated="handlePhotosUpdated"/>
    </div>
    <div class="order-form">
      <div class="order-form-left">
        <div class="order-form-item">
          <p>Name<span>*</span></p>
          <input class="item-input" type="text" v-model="name" placeholder="Name">
        </div>
        <div class="order-form-item">
          <p>Street Address<span>*</span></p>
          <input class="item-input" type="text" v-model="street" placeholder="Street Address">
        </div>
        <div class="order-form-item">
          <p>City, state, Zip Code<span>*</span></p>
          <input class="item-input" type="text" v-model="city" placeholder="City, state, Zip Code">
        </div>
        <div class="order-form-item">
          <p>Phone Number<span>*</span></p>
          <input class="item-input" type="text" v-model="phone" placeholder="Phone Number">
        </div>
      </div>
      <div class="order-form-right">
        <div class="order-form-item">
          <p>Place of Purchase, Store Name Or Website<span>*</span></p>
          <input class="item-input" type="text" v-model="place" placeholder="Place of Purchase, Store Name Or Website">
        </div>
        <div class="order-form-item">
          <div class="order-form-item">
            <p>Purchase Product Name<span>*</span></p>
            <input class="item-input" type="text" v-model="productName" placeholder="Purchase Product Name">
          </div>
          <p>Email Address<span>*</span></p>
          <input class="item-input" type="text" v-model="email" placeholder="Email Address">
        </div>
      </div>
    </div>

    <div>
      <div class="order-form-item">
        <p>Questions to Supplier</p>
        <textarea class="textarea-question" v-model="question" placeholder="Questions to Supplier"></textarea>
      </div>
    </div>

    <div class="send">
      <button class="" @click="submitForm()">Submit</button>
    </div>
  </div>
</div>


  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>
</template>

<script>

import Upload from "@/components/Upload";

import {getCart, subForm} from "@/network/main";
export default {
  name: "Order",
  components: {
    Upload
  },
  data() {
    return {
     selectedIds: {},
     cartList:[],
     name:'',
     street:'',
     city:'',
     phone:'',
     place:'',
     email:'',
     productName:'',
     question:'',
     photos: [], // 图片和描述数组
     isPopupVisible: false,
     popupText: '',
    }
  },
  created() {
    //拿缓存数据
    const selectedIdsData = localStorage.getItem('selectedIds');
    if (selectedIdsData) {
      this.selectedIds = JSON.parse(selectedIdsData);
    }
    //获取购物车
    this.getCart();
  },
  methods: {
    //orderList
    getCart() {
      const ids = Object.keys(this.selectedIds);
      const idString = ids.join(',');
      console.log(idString)
      getCart(idString).then(res => {
        if (res.data.code === 200) {
          const cartData = res.data.data;
          this.cartList = cartData.map(item => {
            const quantity = this.selectedIds[item.id];
            return {
              ...item,
              quantity: quantity
            };
          });
        }
      });
    },
    //图片和描述
    handlePhotosUpdated(photos) {
      // 在这里处理上传的图片和描述数据
      this.photos = photos.map((photo, index) => ({
        [`photos[${index}][file]`]: photo.file,
        [`photos[${index}][description]`]: photo.description
      }));
    },
    //提交订单
    submitForm(){
      // 构造 components 数组
      const components = this.cartList.map((item, index) => ({
        [`components[${index}][id]`]: item.id,
        [`components[${index}][number]`]: item.quantity
      }));

      //formData
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('place_of_purchase', this.place);
      formData.append('street_address', this.street);
      if (this.email) {
        if (!this.validateForm()) {
          return;
        }
        formData.append('email_address', this.email);
      }
      formData.append('city_state_zip_code', this.city);
      formData.append('product_name', this.productName);
      formData.append('phone_number', this.phone);
      formData.append('questions_supplier', this.question);

      // 添加 photos 数据到 formData---必填
      // if (this.photos.length > 0) {
      //   for (let index = 0; index < this.photos.length; index++) {
      //     const photo = this.photos[index];
      //     for (const key in photo) {
      //       formData.append(key, photo[key]);
      //     }
      //     // 判断 photo.description 是否为空
      //     const descriptionKey = `photos[${index}][description]`;
      //     if (!photo[descriptionKey]) {
      //       this.isPopupVisible = true;
      //       this.popupText = 'Please provide a description for each photo.';
      //       setTimeout(() => {
      //         this.isPopupVisible = false;
      //       }, 2000);
      //       return;
      //     }
      //   }
      // }

      //描述不是必填
      if (this.photos.length > 0) {
        for (let index = 0; index < this.photos.length; index++) {
          const photo = this.photos[index];
          for (const key in photo) {
            formData.append(key, photo[key]);
          }
        }
      }

      // 添加 components 数据到 formData
      components.forEach((component) => {
        for (const key in component) {
          formData.append(key, component[key]);
        }
      });

      this.isPopupVisible = true
      this.popupText="Please Wait....."

      if (!this.name || !this.place || !this.street || !this.email || !this.city || !this.productName || !this.phone === 0) {
        this.isPopupVisible = true
        this.popupText="Please fill in the required fields"
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000);
        return;
      }

      //接口
      subForm(formData).then((res)=>{
        this.isPopupVisible = false;
        if (res.data.code === 200) {
          this.$router.push({ path: '/result', query: { success: 'true' } });
          //清空缓存数据
          this.selectedIds = {};
          localStorage.removeItem('selectedIds');
          this.cartList = [];
          // 触发自定义事件
          const event = new CustomEvent('selectedIdsLengthUpdated', { detail: 0 });
          window.dispatchEvent(event);
        } else {
          this.$router.push({ path: '/result', query: { success: 'false', errorMessage: res.data.message } });
        }
      }).catch(err=> {
        console.log(err)
      })
    },

    // 验证电子邮件
    validateForm() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email || !emailRegex.test(this.email)) {
        this.isPopupVisible = true,
        this.popupText="Please fill in the correct email format"
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000);
        return false;
      }
      return true; // 表单验证通过
    },
  },
}
</script>

<style scoped>
.order h1{
  padding: 30px 0;
}
.cart-table{
  border-top: 1px solid #CBCBCB;
  border-left: 1px solid #CBCBCB;
  border-right: 1px solid #CBCBCB;
}
.cart-title{
  background: #EEEEEE;
  font-size: 24px;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
}
.cart-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #CBCBCB;
  padding: 20px;
}
.cart-item-1{
  width: 500px;
}
.cart-item-2{
  width: 160px;
}
.cart-item-3{
  width: 30px;
}
.cart-item-1-txt{
  font-weight: bold;
}
.product-info{
  display: flex;
  align-items: center;
}
.product-info-img{
  width: 78px;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D8D8D8;
  margin-right: 15px;
}
.product-info-img img{
  max-height: 78px;
  padding: 5px;
}
.product-info-txt{
  display: flex;
  flex-direction: column;
}
.product-info-txt>div{
  padding-top: 10px;
}
.product-info-txt>div span:first-child{
  background: #b7b7b7;
  padding: 5px;
  color: #fff;
  display: inline-block;
  margin-right: 20px;
  border-radius: 4px;
}
.product-info-txt>div span:last-child{
  font-weight: bold;
  font-size: 18px;
}
.product-no span{
  background: #b7b7b7;
  padding: 5px;
  color: #fff;
  display: inline;
  border-radius: 4px;
}
.thanks{
  padding: 30px 0;
}
.thanks p{
  color: #595959;
}

.order-tips{
  padding-bottom: 20px;
}
.order-tips p{
  color: #ff0000;
  font-weight: bold;
}

.upload h3{
  font-weight: normal;
}
.upload h3 span{
   color: #939393;
  font-size: 14px;
  padding-left: 20px;
}
.order-form{
  display: flex;
  justify-content: space-between;
}
.order-form-left, .order-form-right{
  width: 48.5%;
}
.order-form-item{
  width: 100%;
  margin-bottom: 20px;
}
.order-form-item p{
  font-weight: bold;
}
.order-form-item p span{
  color: #ff0000;
}
.order-form-item .item-input{
  width: 100%;
  border: 1px solid #8C8C8C;
  height: 50px;
  border-radius: 4px;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 16px;
}
.send button{
  width: 150px;
  height: 56px;
  margin-bottom: 40px;
  border-radius: 4px;
  border: none;
  font-size: 18px;
  color: #fff;
  background: var(--background);
  cursor: pointer;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,.8);
  padding: 15px;
  border-radius: 4px;
  color: #fff;
}
.textarea-question{
  width: 100%;
  resize: none;
  height: 120px;
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #8C8C8C;
  border-radius: 4px;
}

@media only screen and (min-width:0px) and (max-width:767px){
  .order h1{
    font-size: 24px;
  }
  .cart-item-1{
    width:50%;
  }
  .cart-item-2{
    width: 40%;
  }
  .product-info{
    flex-wrap: wrap;
  }
  .product-info-img{
    margin-right: 0;
    margin-bottom: 10px;
  }
  .order-form{
    flex-wrap: wrap;
  }
  .order-form-left, .order-form-right{
    width:100%;
  }
}
</style>